import { DomainReportDocTypeString, ServiceOrderStage } from '@dostavkee/contracts-console/entities'
import { ChipVariant } from '@dostavkee/web-ui'

export const STAGE_TO_TEXT = new Map<ServiceOrderStage, string>([
    [ServiceOrderStage.OrderStageCreated, 'Запланирован'],
    [ServiceOrderStage.OrderStageSearching, 'Поиск курьера'],
    [ServiceOrderStage.OrderStageCourierAccepted, 'Курьер в пути'],
    [ServiceOrderStage.OrderStageCourierArrived, 'Курьер на месте'],
    [ServiceOrderStage.OrderStageCourierPickedUp, 'В процессе'],
    [ServiceOrderStage.OrderStageOrderCompleted, 'Доставлен'],
    [ServiceOrderStage.OrderStageCourierCanceled, 'Отменён курьером'],
    [ServiceOrderStage.OrderStageClientCanceled, 'Отменён'],
    [ServiceOrderStage.OrderStageCanceledByTimeout, 'Курьер не найден'],
    [ServiceOrderStage.OrderStageClientArchived, 'Отменён'],
])

export const STAGE_TO_CHIP = new Map<ServiceOrderStage, ChipVariant>([
    [ServiceOrderStage.OrderStageCreated, ChipVariant.Warning],
    [ServiceOrderStage.OrderStageSearching, ChipVariant.Warning],
    [ServiceOrderStage.OrderStageCourierAccepted, ChipVariant.Info],
    [ServiceOrderStage.OrderStageCourierArrived, ChipVariant.InfoDeep],
    [ServiceOrderStage.OrderStageCourierPickedUp, ChipVariant.Success],
    [ServiceOrderStage.OrderStageOrderCompleted, ChipVariant.SuccessDeep],
    [ServiceOrderStage.OrderStageCourierCanceled, ChipVariant.Error],
    [ServiceOrderStage.OrderStageClientCanceled, ChipVariant.Error],
    [ServiceOrderStage.OrderStageCanceledByTimeout, ChipVariant.ErrorDeep],
    [ServiceOrderStage.OrderStageClientArchived, ChipVariant.Error],
])

export const STAGES_ALLOWED_CALL_COURIER = new Set([
    ServiceOrderStage.OrderStageCourierAccepted,
    ServiceOrderStage.OrderStageCourierArrived,
    ServiceOrderStage.OrderStageCourierPickedUp,
    ServiceOrderStage.OrderStageCourierCanceled,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_CANCEL_ORDER = new Set([
    ServiceOrderStage.OrderStageCreated,
    ServiceOrderStage.OrderStageSearching,
    ServiceOrderStage.OrderStageCourierAccepted,
    ServiceOrderStage.OrderStageCourierArrived,
    ServiceOrderStage.OrderStageCourierPickedUp,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_ARCHIVE_ORDER = new Set([
    ServiceOrderStage.OrderStageCourierCanceled,
    ServiceOrderStage.OrderStageCanceledByTimeout,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_REPEAT_ORDER = new Set([
    ServiceOrderStage.OrderStageOrderCompleted,
    ServiceOrderStage.OrderStageCourierCanceled,
    ServiceOrderStage.OrderStageClientCanceled,
    ServiceOrderStage.OrderStageCanceledByTimeout,
    ServiceOrderStage.OrderStageClientArchived,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_POLLING_LOCATION = new Set([
    ServiceOrderStage.OrderStageCourierAccepted,
    ServiceOrderStage.OrderStageCourierArrived,
    ServiceOrderStage.OrderStageCourierPickedUp,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_POLLING_ORDER = new Set([
    ServiceOrderStage.OrderStageSearching,
    ServiceOrderStage.OrderStageCourierAccepted,
    ServiceOrderStage.OrderStageCourierArrived,
    ServiceOrderStage.OrderStageCourierPickedUp,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_SHARE_ORDER = new Set([
    ServiceOrderStage.OrderStageCourierAccepted,
    ServiceOrderStage.OrderStageCourierArrived,
    ServiceOrderStage.OrderStageCourierPickedUp,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_SHOW_ORDER_ALERT = new Set([
    ServiceOrderStage.OrderStageCourierCanceled,
    ServiceOrderStage.OrderStageCanceledByTimeout,
    ServiceOrderStage.OrderStageCourierArrived,
] as ServiceOrderStage[])

export const STAGES_ALLOWED_INCREASE_PRICE_ORDER = new Set([
    ServiceOrderStage.OrderStageSearching,
] as ServiceOrderStage[])

export const COURIER_TYPE_TO_TEXT: { [key: string]: string } = {
    walking: 'Вело/пеший курьер',
    auto: 'Автокурьер',
}

export type PAYMENT_STATUS = 'success' | 'fail'

export type PAYMENT_METHOD = 'card' | 'invoice' | 'sbp'

export enum PaymentStatusCode {
    NEW = 'NEW',
    FORM_SHOWED = 'FORM_SHOWED',
    AUTHORIZING = 'AUTHORIZING',
    '3DS_CHECKING' = '3DS_CHECKING',
    '3DS_CHECKED' = '3DS_CHECKED',
    AUTHORIZED = 'AUTHORIZED',
    CONFIRMING = 'CONFIRMING',
    CONFIRMED = 'CONFIRMED',
    REVERSING = 'REVERSING',
    PARTIAL_REVERSED = 'PARTIAL_REVERSED',
    REVERSED = 'REVERSED',
    REFUNDING = 'REFUNDING',
    PARTIAL_REFUNDED = 'PARTIAL_REFUNDED',
    REFUNDED = 'REFUNDED',
    СANCELED = 'СANCELED',
    DEADLINE_EXPIRED = 'DEADLINE_EXPIRED',
    REJECTED = 'REJECTED',
    AUTH_FAIL = 'AUTH_FAIL',
}

export enum ERROR_CODES {
    USER_ALREADY_EXIST = 1001,
    USER_NOT_FOUND = 1002,
    USER_REFRESH_TOKEN_COOKIE_NOT_FOUND = 1003,
    USER_REFRESH_TOKEN_EXPIRED = 1004,
    USER_EMAIL_CODE_DOESNT_EXISTS = 1005,
    USER_ALREADY_HAS_COMPANY = 1006,
    USER_HAS_NO_RELATION_WITH_THE_COMPANY = 1007,
    USER_VERIFICATION_CODE_DOESNT_EXISTS = 1008,

    CITY_ALREADY_EXISTS = 2001,

    COMPANY_ALREADY_EXISTS = 3001,
    COMPANY_NOT_FOUND = 3002,
    COMPANY_ALREADY_SET_CATEGORY = 3003,
    COMPANY_UPDATE_PROHIBITED = 3004,
    COMPANY_NOT_VERIFIED = 3006,

    CATEGORY_ALREADY_EXISTS = 4001,
    CATEGORY_NOT_FOUND = 4002,

    ADDRESS_NOT_FOUND = 5001,

    VALIDATION_ERROR = 6000,
    ORDER_ALREADY_EXISTS = 6001,
    ORDER_HAS_NO_RELATION_WITH_THE_USER = 6002,
    ORDER_NOT_FOUND = 6003,
    DRIVEE_CREATION_ORDER = 6004,
    ORDER_INAPPROPRIATE_EVENT = 6005,
    INSUFFICIENT_FUNDS_TO_CREATE_AN_ORDER = 6006,
    ACTIVE_ORDERS_LIMIT_EXCEEDED_ERROR_MESSAGE = 6007,
    ORDER_NO_ONE_IS_AVAILABLE_ERROR_CODE = 6008,
    ORDER_UNATTRACTIVE_PRICE_ERROR_CODE = 6009,
    ORDER_OFFER_A_HIGHER_FARE_ERROR_CODE = 6010,
    ORDER_REQUEST_IS_NOT_ACCEPTED_ERROR_CODE = 6011,
    ORDER_EDIT_COOLDOWN_ERROR_CODE = 6013,
    ORDER_MIN_AMOUNT_ERROR_CODE = 6014,

    ORDER_NOT_ACTIVE_ERROR_CODE = 6015,
    ORDER_NOT_IN_PROCESS_ERROR_CODE = 6016,

    INVOICE_CREATION = 7001,
}

export const TIMEOUT_RESEND_EMAIL_CODE_SECONDS = 60

export const ORDER_FORM_PERSISTED_STORAGE_KEY = '@dostavkee/order-form'

export const ORDER_FORM_PERSISTED_SENDER_STORAGE_KEY = '@dostavkee/order-form-sender'

export const REFRESH_INTERVAL_ACTIVE_ORDERS =
    typeof __GLOBAL__.VITE_REFRESH_INTERVAL_ACTIVE_ORDERS === 'string'
        ? Number.parseInt(__GLOBAL__.VITE_REFRESH_INTERVAL_ACTIVE_ORDERS, 10)
        : 4000

export const REFRESH_INTERVAL_BALANCE =
    typeof __GLOBAL__.VITE_REFRESH_INTERVAL_BALANCE === 'string'
        ? Number.parseInt(__GLOBAL__.VITE_REFRESH_INTERVAL_BALANCE, 10)
        : 10_000

export const REFRESH_INTERVAL_LOCATION =
    typeof __GLOBAL__.VITE_REFRESH_INTERVAL_LOCATION === 'string'
        ? Number.parseInt(__GLOBAL__.VITE_REFRESH_INTERVAL_LOCATION, 10)
        : 4000

export const REFRESH_INTERVAL_COURIERS =
    typeof __GLOBAL__.VITE_REFRESH_INTERVAL_COURIERS === 'string'
        ? Number.parseInt(__GLOBAL__.VITE_REFRESH_INTERVAL_COURIERS, 10)
        : 8000

export const ORDER_UPDATE_AMOUNT_STEP = __GLOBAL__.VITE_ORDER_UPDATE_AMOUNT_STEP
    ? Number.parseInt(__GLOBAL__.VITE_ORDER_UPDATE_AMOUNT_STEP, 10)
    : 20

export const DOCUMENT_TYPE_TO_TEXT = new Map<DomainReportDocTypeString, string>([
    [DomainReportDocTypeString.ReportDocTypeActStr, 'Акт'],
    [DomainReportDocTypeString.ReportDocTypeAgentReportStr, 'Отчёт агента'],
    [DomainReportDocTypeString.ReportDocTypeUpdStr, 'УПД'],
])

export const PIN_ICON_WIDTH = 36

export const PIN_ICON_HEIGHT = 58
